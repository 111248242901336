/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-deprecated */
import * as React from 'react';
import {MobileFiltersModal} from './MobileFiltersModal/MobileFiltersModal';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';
import {
  withTranslations,
  IProvidedTranslationProps,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import s from './MobileFilters.scss';
import autobind from 'auto-bind-es5';

interface MobileFiltersProps extends IGalleryGlobalProps, IProvidedTranslationProps {}
interface MobileFiltersState {
  renderKey: number;
}

class MobileFiltersComp extends React.Component<MobileFiltersProps, MobileFiltersState> {
  private windowScrollY: number;
  private originalBodyTop: string;
  private readonly filtersBtnRef: React.RefObject<HTMLButtonElement>;
  private readonly modalContainerRef: React.RefObject<HTMLDivElement>;
  private readonly modalHeaderId = 'wixstores-filters-modal-header';
  public state = {
    renderKey: 1,
  };

  constructor(props) {
    super(props);
    autobind(this);
    this.filtersBtnRef = React.createRef<HTMLButtonElement>();
    this.modalContainerRef = React.createRef<HTMLDivElement>();
  }

  private getSelectedFiltersCount() {
    return `(${this.props.globals.numberOfSelectedFilterTypes})`;
  }

  private openMobileFiltersModal() {
    this.saveBodyTopPosition();

    this.props.globals.toggleFiltersModalVisibility(true);
    !this.props.globals.isPreviewMode && document.body.classList.add('filters-modal-open');
    document.body.style.top = `-${this.windowScrollY}px`;
    if (this.props.globals.experiments.fixGalleryMobileFiltersFocus) {
      this.modalContainerRef.current?.focus();
    }
  }

  private closeMobileFiltersModal() {
    document.body.classList.remove('filters-modal-open');
    this.props.globals.toggleFiltersModalVisibility(false);

    this.restoreBodyTopPosition();
    this.filtersBtnRef.current?.focus();
  }

  private saveBodyTopPosition() {
    this.originalBodyTop = document.body.style.top;
    this.windowScrollY = window.scrollY;
  }

  private restoreBodyTopPosition() {
    document.body.style.top = this.originalBodyTop;
    window.scrollTo(window.scrollX, this.windowScrollY);
  }

  private onModalClose(nextProps: IGalleryGlobalProps) {
    return (
      this.props.globals.shouldShowMobileFiltersModal === true &&
      nextProps.globals.shouldShowMobileFiltersModal === false
    );
  }

  public componentWillReceiveProps(nextProps: IGalleryGlobalProps) {
    if (this.onModalClose(nextProps)) {
      setTimeout(() => {
        this.setState(({renderKey}) => ({renderKey: renderKey + 1}));
      }, 500);
    }
  }

  public render() {
    const {clearFilters, numberOfSelectedFilterTypes, shouldShowClearFilters, shouldShowMobileFiltersModal} =
      this.props.globals;

    return (
      <>
        <button
          onClick={() => this.openMobileFiltersModal()}
          ref={this.filtersBtnRef}
          className={s.mobileFiltersButton}
          data-hook="mobile-filters-button">
          {this.props.t('mobileFiltersButtonText')}
          {numberOfSelectedFilterTypes > 0 && <span className={s.filtersCount}> {this.getSelectedFiltersCount()}</span>}
        </button>
        <MobileFiltersModal
          className="filters-modal"
          closeModal={() => this.closeMobileFiltersModal()}
          clearFilters={clearFilters}
          shouldShowClearFilters={shouldShowClearFilters}
          isOpen={shouldShowMobileFiltersModal}
          key={this.state.renderKey}
          modalRef={this.modalContainerRef}
          filtersBtnRef={this.filtersBtnRef}
          modalHeaderId={this.modalHeaderId}
        />
      </>
    );
  }
}

export const MobileFilters = withGlobals(withTranslations()(MobileFiltersComp));
